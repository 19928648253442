<template>
	<div id="ag-grid-demo">
		<div v-if="currentTimestamp != null" class="vx-col w-full mb-base">
			<vs-alert active="true" icon="new_releases">The below data is for the time period of {{ formattedTimestamp }}. <a href="javascript:void(0);" @click="removeTimestamp"><u>Click here</u></a> for latest data.</vs-alert>
		</div>

		<vx-card>
			<!-- TABLE ACTION ROW -->
			<div class="flex flex-wrap justify-between items-center">

				<!-- ITEMS PER PAGE -->
				<div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
					<vs-dropdown vs-trigger-click class="cursor-pointer">
						<div class="p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium vs-dropdown-radius">
							<span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ tableData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : tableData.length }} of {{ tableData.length }}</span>
							<feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
						</div>
						<!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
						<vs-dropdown-menu>
							<vs-dropdown-item @click="gridApi.paginationSetPageSize(500)">
								<span>500</span>
							</vs-dropdown-item>
							<vs-dropdown-item @click="gridApi.paginationSetPageSize(1000)">
								<span>1000</span>
							</vs-dropdown-item>
							<vs-dropdown-item @click="gridApi.paginationSetPageSize(1500)">
								<span>1500</span>
							</vs-dropdown-item>
							<vs-dropdown-item @click="gridApi.paginationSetPageSize(1500)">
								<span>2000</span>
							</vs-dropdown-item>
						</vs-dropdown-menu>
					</vs-dropdown>
				</div>

				<!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
				<div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
					<vs-input class="mb-4 md:mb-0 mr-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
					<vs-button color="dark" type="border" icon="icon-download" icon-pack="feather" class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"></vs-button>
				</div>
			</div>
			<ag-grid-vue
			:gridOptions="gridOptions"
			class="ag-theme-material w-100 my-4 ag-grid-table"
			:columnDefs="columnDefs"
			:defaultColDef="defaultColDef"
			:rowData="tableData"
			rowSelection="multiple"
			:enableRangeSelection="true"
			:suppressCopyRowsToClipboard="true"
			colResizeDefault="shift"
			:suppressDragLeaveHidesColumns="true"
			:animateRows="false"
			:floatingFilter="true"
			:pagination="true"
			:paginationPageSize="paginationPageSize"
			:suppressPaginationPanel="true"
			:rowClassRules="rowClassRules"
			:statusBar="statusBar"
			:sideBar="sideBar"
			:rowHeight="38"
			@column-resized="onColumnResized">
		</ag-grid-vue>
		<vs-pagination
		:total="totalPages"
		:max="maxPageNumbers"
		v-model="currentPage" />
		<!-- <VuePerfectScrollbar ref="agGridTablePs" class="scroll-area" :settings="psSettings" /> -->
	</vx-card>
</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	export default {
		components: {
			AgGridVue,
		},
		data() {
			return {
				searchQuery: '',
				gridOptions: {},
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				columnDefs: [
				{
					headerName: 'Domain',
					field: 'domain',
					minWidth: 275,
					filter: "agTextColumnFilter",
					pinned: 'left',
					cellClass: "cell-wrap-text",
					cellRenderer: function(params) {
						return `<a href="https://anonymto.com/?https://www.whois.com/whois/${params.value}" target="_blank">${params.value}</a>`
					}
				},
				{
					headerName: 'Suspicion Level',
					field: 'suspicion_level',
					filter: true,
					minWidth: 115,
				},
				{
					headerName: 'Domain ID',
					field: 'domain_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'TLD',
					field: 'tld',
					filter: true,
					minWidth: 100,
					width: 100,
				},
				{
					headerName: 'Registrar',
					field: 'registrar_organization',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Registrar ID',
					field: 'registrar_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Transaction Date',
					field: 'transaction_date',
					filter: "agTextColumnFilter",
					minWidth: 200,
					cellClass: "cell-wrap-text",
				},
				{
					headerName: 'Gibberish Score',
					field: 'gibberish_score',
					filter: "agTextColumnFilter",
					minWidth: 220,
					cellClass: "cell-wrap-text",
				},
				{
					headerName: 'Is Gibberish',
					field: 'gibberish_flag',
					filter: true,
					minWidth: 150,
				},
				{
					headerName: 'Is Same Length',
					field: 'same_length_flag',
					filter: true,
					minWidth: 150,
				},
				{
					headerName: 'Is Short',
					field: 'short_length_flag',
					filter: true,
					minWidth: 150,
				},
				{
					headerName: 'Contains Int',
					field: 'contains_integers_flag',
					filter: true,
					minWidth: 150,
				},
				{
					headerName: 'Common Substring',
					field: 'common_substring_flag',
					filter: true,
					minWidth: 150,
				},
				{
					headerName: 'Reviewed',
					field: 'spam_reviewed',
					filter: true,
					minWidth: 150,
				},
				],
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			}
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			currentTimestamp: function(){
				return this.$store.getters.getCurrentTimestamp;
			},
			formattedTimestamp: function(){
				return this.currentTimestamp != null ? new Date(this.currentTimestamp*1000) : this.currentTimestamp;
			},
			tableData: function(){
				return this.$store.getters.getStoreSpamReport;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
		},
		methods: {
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			toggleColumnPinned(val) {
				if(val <= 576) {
					this.maxPageNumbers = 4;
					this.gridOptions.columnApi.setColumnPinned('domain', null);
				}
				else {
					this.gridOptions.columnApi.setColumnPinned('domain', 'left')
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			updateSearchQuery(val) {
				this.gridApi.setQuickFilter(val);
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			highlightSpam() {
				this.rowClassRules = {
					'ag-row-info': 'data.suspicion_level == "low"',
					'ag-row-warning': 'data.suspicion_level == "medium"',
					'ag-row-danger': 'data.suspicion_level == "high"'
				}
			},
			removeTimestamp() {
				this.$store.dispatch('removeCurrentTimestamp');
				// Get Spam Report Data
				this.$store.dispatch("getStoreSpamReport");
			},
		},
		beforeMount() {
			// Highlight spam columns
			this.highlightSpam();

			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid statusbar
			this.statusBar = {
				statusPanels: [
				{
					statusPanel: "agTotalRowCountComponent",
					align: "left"
				},
				{
					statusPanel: "agFilteredRowCountComponent",
					align: "right"
				}
				]
			};

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};
		},
		mounted() {
			this.gridApi = this.gridOptions.api;
			// const el = document.querySelector('.ag-body-viewport');
			// console.log(this.$refs.agGridTablePs)
			// this.$refs.agGridTablePs.__init(el)
			// this.$refs.agGridTablePs.update()

			// Resize to fit columns
			this.sizeColumnsToFit();

			// Reset pinned columns on mobile
			this.toggleColumnPinned(this.windowWidth);

			// Page Refresh Event
			this.$root.$on('refreshPage', () => {
				// Check if Timestamp is present
				if(this.currentTimestamp) {
					// Get Spam Report Data (Based on Timestamp)
					this.$store.dispatch("getStoreSpamReport", this.currentTimestamp);
				}
				else {
					// Get Spam Report Data
					this.$store.dispatch("getStoreSpamReport");
				}
			});

		}
	}

</script>